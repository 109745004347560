import React, { useContext, useEffect } from "react";
import { navigate } from "gatsby";
import { getLink } from "../utils/getLink";
import { DataContext } from "../providers/DataProvider";

const NotFoundPage = () => {
  const { pageContext } = useContext(DataContext);

  useEffect(() => {
    const redirects = [
      {
        from: "/eventmanagementsoftware/",
        to: getLink({ key: "ourdevelopment", pageContext }),
      },
      {
        from: "/meetingroombooking/",
        to: getLink({ key: "meetingrooms", pageContext }),
      },
      {
        from: "/allergensdietaries/",
        to: getLink({ key: "ticketing", pageContext }),
      },
    ];
    const path = window.location.pathname;
    const redirect = redirects.find((r) => r.from === path);

    if (redirect) {
      window.location.replace(redirect.to);
    } else {
      navigate(getLink({ key: "home", pageContext }), { replace: true });
    }
  }, []);

  return <div></div>;
};

export default NotFoundPage;
